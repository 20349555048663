import React, { useContext } from 'react'
import { Link } from 'gatsby';
import { FaCartShopping } from "react-icons/fa6";
import { AccountContext } from '../../utils/AccountContext';

const Cart = () => {
    const { cart } = useContext(AccountContext);
    return (
        <Link to="/cart" className="text-[#071e43] hover:text-[#fd5901] text-[18px] font-medium relative">
            {cart && cart?.lineItems?.length > 0 &&
                <div className='bg-[#fd5901] w-[20px] h-[20px] flex justify-center items-center rounded-full absolute -top-[7px] -right-[10px]'>
                    <p className="m-0 text-white text-[12px] font-normal">{cart?.lineItems?.length}</p>
                </div>
            }
            <FaCartShopping className="cart-icon" size={26} />
        </Link>)
}

export default Cart;