exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-cancellation-policy-js": () => import("./../../../src/pages/cancellation-policy.js" /* webpackChunkName: "component---src-pages-cancellation-policy-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-cities-js": () => import("./../../../src/pages/cities.js" /* webpackChunkName: "component---src-pages-cities-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-customer-js": () => import("./../../../src/pages/customer.js" /* webpackChunkName: "component---src-pages-customer-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iptv-compliance-regulatory-js": () => import("./../../../src/pages/iptv-compliance-regulatory.js" /* webpackChunkName: "component---src-pages-iptv-compliance-regulatory-js" */),
  "component---src-pages-iptv-customer-support-js": () => import("./../../../src/pages/iptv-customer-support.js" /* webpackChunkName: "component---src-pages-iptv-customer-support-js" */),
  "component---src-pages-iptv-installation-guide-js": () => import("./../../../src/pages/iptv-installation-guide.js" /* webpackChunkName: "component---src-pages-iptv-installation-guide-js" */),
  "component---src-pages-isp-js": () => import("./../../../src/pages/isp.js" /* webpackChunkName: "component---src-pages-isp-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-privacy-policy-kr-js": () => import("./../../../src/pages/privacy-policy-kr.js" /* webpackChunkName: "component---src-pages-privacy-policy-kr-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-searchnews-js": () => import("./../../../src/pages/searchnews.js" /* webpackChunkName: "component---src-pages-searchnews-js" */),
  "component---src-pages-selfcare-app-js": () => import("./../../../src/pages/selfcare-app.js" /* webpackChunkName: "component---src-pages-selfcare-app-js" */),
  "component---src-pages-sitemap-html-js": () => import("./../../../src/pages/sitemap.html.js" /* webpackChunkName: "component---src-pages-sitemap-html-js" */),
  "component---src-pages-subscription-packages-iptv-js": () => import("./../../../src/pages/subscription-packages-iptv.js" /* webpackChunkName: "component---src-pages-subscription-packages-iptv-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-verification-js": () => import("./../../../src/pages/verification.js" /* webpackChunkName: "component---src-pages-verification-js" */),
  "component---src-pages-verify-js": () => import("./../../../src/pages/verify.js" /* webpackChunkName: "component---src-pages-verify-js" */),
  "component---src-templates-blog-details-js": () => import("./../../../src/templates/blog-details.js" /* webpackChunkName: "component---src-templates-blog-details-js" */),
  "component---src-templates-cities-detail-js": () => import("./../../../src/templates/cities-detail.js" /* webpackChunkName: "component---src-templates-cities-detail-js" */)
}

