import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { CopyToClipboard } from "../utils/copyToClipboard";
import { SlLocationPin } from "react-icons/sl";
import { FaRegCopy } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { GetRequest } from "../utils/requests";

const FooterContact = () => {
  const phone = "+919803596035";
  const phoneLink = `tel:${phone}`;
  const email = "info@skypro.co.in";
  const [details, setDetails] = useState([]);
  const callOnPhone = () => {
    window.location.href = phoneLink;
  };
  const getDetailsData = async () => {
    try {
      const response = await GetRequest(`${process.env.REACT_APP_API_URL}/details`);
      setDetails(response?.data || []);
    } catch (error) {
      console.error('Error fetching data from API:', error);
    }
  };
  useEffect(() => {
    getDetailsData();
  }, []);
  console.log("details<<", details);
  return (
    <section className="bg-[#1f3455] py-[55px] text-white">
      <div className="container">
        <div className="grid md:grid-cols-3 grid-cols-1 lg:gap-[40px] lg:gap-y-[20px] md:gap-[15px] gap-[25px]">
          <div className="flex justify-center">
            <span className="flex justify-center items-center h-[50px] w-[50px] duration-500 rounded-[8px] bg-[#071e43] text-[#ef3304]">
              <SlLocationPin className="text-[30px]" />
            </span>
            {details?.map((item) => {
              return (
                <div className="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_50px)] w-[calc(100%_-_40px)]">
                  <h4 className="text-white">{item?.titleIst}</h4>
                  <p className="mb-0">{item?.descIst}</p>
                </div>
              )
            })}
          </div>
          <div className="flex justify-center">
            <span className="flex justify-center items-center h-[50px] w-[50px] duration-500 rounded-[8px] bg-[#071e43] text-[#ef3304]">
              <SlLocationPin className="text-[30px]" />
            </span>
            {details?.map((item) => {
              return (
                <div className="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_50px)] w-[calc(100%_-_40px)]">
                  <h4 className="text-white">{item?.titleSec}</h4>
                  <p className="mb-0">{item?.descSec}</p>
                </div>
              )
            })}
          </div>
          <div className="flex justify-center">
            <span className="flex justify-center items-center h-[50px] w-[50px] duration-500 rounded-[8px] bg-[#071e43] text-[#ef3304]">
              <SlLocationPin className="text-[30px]" />
            </span>
            {details?.map((item) => {
              return (
                <div className="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_50px)] w-[calc(100%_-_40px)]">
                  <h4 className="text-white">{item?.titleThird}</h4>
                  <p className="mb-0">{item?.descThird}</p>
                </div>
              )
            })}
          </div>
          
          <div className="flex justify-center relative">
            <span className="flex justify-center items-center h-[50px] w-[50px] duration-500 rounded-[8px] bg-[#071e43] text-[#ef3304]">
              <MdOutlineMail className="text-[30px]" />
            </span>
            <div className="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_50px)] w-[calc(100%_-_40px)]">
              <h4 className="text-white">Send us a Message</h4>
              <p className="cursor-pointer inline-block" onClick={() => CopyToClipboard(email, "Email copied successfully")}>
                Email us <FaRegCopy className="inline-block ml-[4px]" />
              </p>
            </div>
          </div>
          <div className="flex justify-center">
            <span className="flex justify-center items-center h-[50px] w-[50px] duration-500 rounded-[8px] bg-[#071e43] text-[#ef3304]">
              <FiPhone className="text-[30px]" />
            </span>
            <div className="sm:pl-[20px] pl-[15px] text-left sm:w-[calc(100%_-_50px)] w-[calc(100%_-_40px)]">
              <h4 className="text-white">Give us a Call</h4>
              <p>
                <Link to={phoneLink} onClick={callOnPhone}>
                  +(91) 9803596035
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterContact;
