import React from 'react'
import Header from './headers'
import Footer from './footer'
import FooterContact from './footerContact'
import FloatingAction from '../components/shared/floatingAction'
// import { AccountProvider } from '../utils/AccountContext'
import { useLocation } from "react-use";
const Layout = ({ children }) => {
    const location = useLocation();
    const path = location.pathname;
    console.log("location<<", path);
    return (
        <>
            {/* <AccountProvider> */}
            {path !== "/verify/" ? <Header /> : ""}
            {children}
            {path !== "/verify/" ? <FloatingAction /> : ""}
            {path !== "/verify/" ? <FooterContact /> : ""}
            {path !== "/verify/" ? <Footer /> : ""}
            {/* </AccountProvider> */}
        </>
    )
}

export default Layout;