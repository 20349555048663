import React, { createContext, useState, useContext, useEffect } from 'react';
import { GetRequest } from './requests';
// import Client from 'shopify-buy';
import { useStaticQuery, graphql } from 'gatsby';

//INITIALIZE CLIENT TO RETURN CONTENT IN STORE'S PRIMARY LANGUAGE
// const client = Client.buildClient({
//     domain: process.env.SHOPIFY_APP_URL,
//     storefrontAccessToken: process.env.SHOPIFY_STOREFRONT_TOKEN
// });

const defaultStoreContext = {
    cartQuantity: 0,
    cart: {},
    addLineItem: () => { },
    removeLineItem: () => { },
    updateLineItem: () => { },
    addDiscount: () => { },
    removeDiscount: () => { },
    userData: null,
    setUserData: () => { },
    trigger: 0,
    setTrigger: () => { },
    addAddress: () => { },
    resetCartHandler: () => { },
    activeCollection: "",
    setActiveCollection: () => { },
    searchKey: "",
    setSearchKey: () => { },
    filteredSearch: [],
    setFilteredSearch: () => { },
    openCheckout: false,
    setOpenCheckout: () => { },
    deviceQuantity: {},
    setDeviceQuantity: () => { },
    annualDiscount: "",
    setAnnualDiscount: () => { },
    semiAnnualDiscount: "",
    setSemiAnnualDiscount: () => { },
    calculateDiscount: () => { },
    verificationDone: false,
    setVerificationDone: () => { },
    userExist: false,
    setUserExist: () => { },
    userVerification: "",
    setUserVerification: () => { },
    loginType: "",
    setLoginType: () => { },

}
export const AccountContext = createContext(defaultStoreContext);

export const AccountProvider = ({ children }) => {
    const [packageData, setPackageData] = useState("");
    const [view, setView] = useState(false);
    const [blogs, setBlogs] = useState([]);
    const [userVerification, setUserVerification] = useState({})
    const [userData, setUserData] = useState(null);
    const [cart, setCart] = useState(null);
    const [trigger, setTrigger] = useState(0);
    const [cartQuantity, setCartQuantity] = useState(0);
    const [openCheckout, setOpenCheckout] = useState(false);
    const [annualDiscount, setAnnualDiscount] = useState("")
    const [semiAnnualDiscount, setSemiAnnualDiscount] = useState("")
    const [productsData, setProductsData] = useState([]);
    const [details, setDetails] = useState([]);
    const [loginType, setLoginType] = useState("");
    const [verificationDone, setVerificationDone] = useState(false);
    const pdata = useStaticQuery(graphql`
        query ProductsQuery {
        shopifyCollection {
          products {
            id
            title
            description
            variants {
              shopifyId
            }
            metafields {
              id
              key
              value
            }
            featuredImage {
              gatsbyImageData(width: 100, layout: CONSTRAINED)
            }
          }
        }
      }
        `)
    //FETCHES CHECKOUT ON PAGE RENDER
    useEffect(() => {
        setProductsData(pdata);
        fetchCheckout();
    }, []);

    // CREATES CHECKOUT TOKEN URL
    const fetchCheckout = async () => {
        try {
            const existingCartId = typeof window !== 'undefined' ? localStorage.getItem('k-checkout') : null;

            if (existingCartId) {
                console.log("Fetching existing cart:", existingCartId);
                const response = await fetch(`https://${process.env.SHOPIFY_APP_URL}/api/2024-04/graphql.json`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Shopify-Storefront-Access-Token': process.env.SHOPIFY_STOREFRONT_TOKEN
                    },
                    body: JSON.stringify({
                        query: `query getCart($cartId: ID!) {
                            cart(id: $cartId) {
                                id
                                checkoutUrl   # Fetch checkout URL
                                createdAt
                                updatedAt
                                cost {
                                    totalAmount {
                                        amount
                                        currencyCode
                                    }
                                }
                                lines(first: 10) {
                                    edges {
                                        node {
                                            id
                                            quantity
                                            merchandise {
                                                ... on ProductVariant {
                                                    id
                                                    title
                                                    product {
                                                        title
                                                    }
                                                    price {
                                                        amount
                                                        currencyCode
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }`,
                        variables: { cartId: existingCartId }
                    })
                });

                const result = await response.json();
                const cartData = result?.data?.cart;

                if (cartData) {
                    const formattedCart = {
                        id: cartData.id,
                        checkoutUrl: cartData.checkoutUrl,  // Include checkout URL
                        lineItems: cartData.lines.edges.map(({ node }) => ({
                            id: node.id,
                            quantity: node.quantity,
                            merchandise: {
                                id: node.merchandise.id,
                                title: node.merchandise.product?.title || node.merchandise.title,
                                price: {
                                    amount: node.merchandise.price.amount,
                                    currencyCode: node.merchandise.price.currencyCode
                                }
                            }
                        })),
                        totalPrice: cartData.cost.totalAmount.amount,
                        currencyCode: cartData.cost.totalAmount.currencyCode
                    };
                    setCart(formattedCart);
                    setCartQuantity(formattedCart.lineItems.length);
                    return;
                }
            }

            // If no cart exists, create a new one
            console.log("Creating a new cart...");
            const newCartResponse = await fetch(`https://${process.env.SHOPIFY_APP_URL}/api/2024-04/graphql.json`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Shopify-Storefront-Access-Token': process.env.SHOPIFY_STOREFRONT_TOKEN
                },
                body: JSON.stringify({
                    query: `mutation {
                        cartCreate {
                            cart {
                                id
                                checkoutUrl  # Include checkout URL in new cart creation
                            }
                        }
                    }`
                })
            });

            const newCartResult = await newCartResponse.json();
            const newCartId = newCartResult?.data?.cartCreate?.cart?.id;
            const checkoutUrl = newCartResult?.data?.cartCreate?.cart?.checkoutUrl;
            if (newCartId) {
                localStorage.setItem('k-checkout', newCartId);
                setCart({ id: newCartId, checkoutUrl, lineItems: [] });
                setCartQuantity(0);
            }
        } catch (error) {
            console.error("Error fetching or creating cart:", error);
        }
    };

    //Add LINEITEM IN CART
    const addLineItem = async (variantId, quantity) => {
        try {
            if (!variantId || !quantity) {
                throw new Error("Variant ID and quantity are required.");
            }

            const cartId = localStorage.getItem('k-checkout');
            if (!cartId) {
                throw new Error("Cart ID is missing. Create a cart first.");
            }

            const response = await fetch(`https://${process.env.SHOPIFY_APP_URL}/api/2024-04/graphql.json`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Shopify-Storefront-Access-Token': process.env.SHOPIFY_STOREFRONT_TOKEN
                },
                body: JSON.stringify({
                    query: `mutation addToCart($cartId: ID!, $lines: [CartLineInput!]!) {
                        cartLinesAdd(cartId: $cartId, lines: $lines) {
                            cart {
                                id
                                checkoutUrl  # Fetch checkout URL again
                                createdAt
                                updatedAt
                                cost {
                                    totalAmount {
                                        amount
                                        currencyCode
                                    }
                                }
                                lines(first: 10) {
                                    edges {
                                        node {
                                            id
                                            quantity
                                            merchandise {
                                                ... on ProductVariant {
                                                    id
                                                    title
                                                    product {
                                                        title
                                                    }
                                                    price {
                                                        amount
                                                        currencyCode
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            userErrors {
                                field
                                message
                            }
                        }
                    }`,
                    variables: {
                        cartId,
                        lines: [{ merchandiseId: variantId, quantity }]
                    }
                })
            });

            const result = await response.json();
            console.log("API Response for addLineItem:", result);

            if (result?.data?.cartLinesAdd?.userErrors?.length) {
                throw new Error(result.data.cartLinesAdd.userErrors[0].message);
            }

            const cartData = result?.data?.cartLinesAdd?.cart;
            if (!cartData) {
                throw new Error("Cart data is missing.");
            }

            // Compute the total price manually
            const newTotalPrice = parseFloat(cartData.cost.totalAmount.amount);

            const formattedCart = {
                id: cartData.id,
                checkoutUrl: cartData.checkoutUrl,  // Ensure checkout URL is included
                lineItems: cartData.lines.edges.map(({ node }) => ({
                    id: node.id,
                    quantity: node.quantity,
                    merchandise: {
                        id: node.merchandise.id,
                        title: node.merchandise.product?.title || node.merchandise.title,
                        price: {
                            amount: node.merchandise.price.amount,
                            currencyCode: node.merchandise.price.currencyCode
                        }
                    }
                })),
                totalPrice: newTotalPrice,
                currencyCode: cartData.cost.totalAmount.currencyCode
            };

            // Ensure real-time updates by merging with previous cart state
            setCart((prevCart) => ({
                ...prevCart,
                checkoutUrl: formattedCart.checkoutUrl, // Update checkout URL in cart state
                lineItems: formattedCart.lineItems.length > 0 ? formattedCart.lineItems : prevCart.lineItems,
                totalPrice: newTotalPrice
            }));

            setCartQuantity(formattedCart.lineItems.length);
        } catch (error) {
            console.error("Error adding line item to cart:", error.message || error);
        }
    };


    //UPDATE LINEITEM IN CART
    const updateLineItem = async (id, quantity) => {
        try {
            if (!id || !quantity) {
                throw new Error("Line item ID and quantity are required.");
            }

            const cartId = localStorage.getItem('k-checkout');
            if (!cartId) {
                throw new Error("Cart ID is missing. Create a cart first.");
            }

            console.log("Updating cart item:", { cartId, id, quantity });

            const response = await fetch(`https://${process.env.SHOPIFY_APP_URL}/api/2024-04/graphql.json`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Shopify-Storefront-Access-Token': process.env.SHOPIFY_STOREFRONT_TOKEN
                },
                body: JSON.stringify({
                    query: `mutation updateCartItem($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
                        cartLinesUpdate(cartId: $cartId, lines: $lines) {
                            cart {
                                id
                                checkoutUrl   # Include checkout URL
                                lines(first: 10) {
                                    edges {
                                        node {
                                            id
                                            quantity
                                            merchandise {
                                                ... on ProductVariant {
                                                    id
                                                    title
                                                    product {
                                                        title
                                                    }
                                                    price {
                                                        amount
                                                        currencyCode
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                cost {
                                    totalAmount {
                                        amount
                                        currencyCode
                                    }
                                }
                            }
                            userErrors {
                                field
                                message
                            }
                        }
                    }`,
                    variables: {
                        cartId,
                        lines: [{ id, quantity }]
                    }
                })
            });

            const result = await response.json();
            if (result?.data?.cartLinesUpdate?.userErrors?.length) {
                throw new Error(result.data.cartLinesUpdate.userErrors[0].message);
            }

            const cartData = result?.data?.cartLinesUpdate?.cart;
            if (!cartData) {
                throw new Error("Cart data is missing.");
            }

            // Update cart structure with checkout URL
            const formattedCart = {
                id: cartData.id,
                checkoutUrl: cartData.checkoutUrl,  // Ensure checkout URL is included
                lineItems: cartData.lines.edges.map(({ node }) => ({
                    id: node.id,
                    quantity: node.quantity,
                    merchandise: {
                        id: node.merchandise.id,
                        title: node.merchandise.product?.title || node.merchandise.title,
                        price: {
                            amount: node.merchandise.price.amount,
                            currencyCode: node.merchandise.price.currencyCode
                        }
                    }
                })),
                totalPrice: cartData.cost.totalAmount.amount,
                currencyCode: cartData.cost.totalAmount.currencyCode
            };

            setCart(formattedCart);
            setCartQuantity(formattedCart?.lineItems?.length || 0);
        } catch (error) {
            console.error("Error updating line item:", error.message || error);
        }
    };

    //REMOVE LINEITEM FROM CART
    const removeLineItem = async (id) => {
        try {
            if (!id) {
                throw new Error("Line item ID is required.");
            }

            const cartId = localStorage.getItem('k-checkout');
            if (!cartId) {
                throw new Error("Cart ID is missing. Create a cart first.");
            }

            console.log("Removing item from cart:", { cartId, id });

            const response = await fetch(`https://${process.env.SHOPIFY_APP_URL}/api/2024-04/graphql.json`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Shopify-Storefront-Access-Token': process.env.SHOPIFY_STOREFRONT_TOKEN
                },
                body: JSON.stringify({
                    query: `mutation removeCartItem($cartId: ID!, $lineIds: [ID!]!) {
                        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
                            cart {
                                id
                                checkoutUrl   # Include checkout URL
                                lines(first: 10) {
                                    edges {
                                        node {
                                            id
                                            quantity
                                            merchandise {
                                                ... on ProductVariant {
                                                    id
                                                    title
                                                    product {
                                                        title
                                                    }
                                                    price {
                                                        amount
                                                        currencyCode
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                cost {
                                    totalAmount {
                                        amount
                                        currencyCode
                                    }
                                }
                            }
                            userErrors {
                                field
                                message
                            }
                        }
                    }`,
                    variables: {
                        cartId,
                        lineIds: [id]
                    }
                })
            });

            const result = await response.json();
            console.log("Remove response:", result);

            if (result?.data?.cartLinesRemove?.userErrors?.length) {
                throw new Error(result.data.cartLinesRemove.userErrors[0].message);
            }

            const cartData = result?.data?.cartLinesRemove?.cart;

            // If the cart is now empty, reset it properly
            if (!cartData || cartData.lines.edges.length === 0) {
                console.log("Cart is empty. Resetting...");
                resetCartHandler();
                return;
            }

            // Update cart properly, preserving checkout URL
            const formattedCart = {
                id: cartData.id,
                checkoutUrl: cartData.checkoutUrl,  // Ensure checkout URL is included
                lineItems: cartData.lines.edges.map(({ node }) => ({
                    id: node.id,
                    quantity: node.quantity,
                    merchandise: {
                        id: node.merchandise.id,
                        title: node.merchandise.product?.title || node.merchandise.title,
                        price: {
                            amount: node.merchandise.price.amount,
                            currencyCode: node.merchandise.price.currencyCode
                        }
                    }
                })),
                totalPrice: cartData.cost.totalAmount.amount,
                currencyCode: cartData.cost.totalAmount.currencyCode
            };

            setCart(formattedCart);
            setCartQuantity(formattedCart?.lineItems?.length || 0);
        } catch (error) {
            console.error("Error removing line item:", error.message || error);
        }
    };

    //RESET CART ON SUCCESSFUL ORDER
    const resetCartHandler = () => {
        // if (typeof window !== 'undefined') {
        //     localStorage.removeItem('k-checkout'); 
        // }
        setCart(null);
        setCartQuantity(0);
    };

    const calculateDiscount = (cart) => {
        if (!cart?.lineItems) return 0;

        let totalDiscount = 0;
        let totalPrice = 0;
        let hasHighQuantity = cart.lineItems.some(item => item.quantity > 19);

        cart?.lineItems?.forEach(item => {
            let price = 2100;
            let originalTotal = 2100 * item.quantity;

            if (hasHighQuantity && item.quantity > 19) {
                price = item.quantity > 99 ? 1700 : 1800;
            }

            let discountedTotal = price * item.quantity;
            totalPrice += discountedTotal;
            totalDiscount += (originalTotal - discountedTotal);
        });

        return totalDiscount;
    };

    let shopifyToken;
    if (typeof window !== 'undefined') {
        shopifyToken = localStorage.getItem('sh-kal-ac');
    }
    // FETCHES DATA WITH TOKEN
    useEffect(() => {
        if (shopifyToken) {
            GetRequest(`${process.env.REACT_APP_API_URL}/user/token/` + shopifyToken).then(response => {
                setUserData(response?.data?.data?.customer);
            }).catch(err => {
                console.log(err);
            })
        } else {
            setUserData(null);
        }
    }, [shopifyToken]);

    const data = {
        packageData,
        setPackageData,
        view,
        setView,
        blogs,
        setBlogs,
        userVerification,
        setUserVerification,
        userData,
        setUserData,
        addLineItem,
        updateLineItem,
        removeLineItem,
        cart,
        setCart,
        trigger,
        setTrigger,
        cartQuantity,
        openCheckout,
        setOpenCheckout,
        annualDiscount,
        setAnnualDiscount,
        semiAnnualDiscount,
        setSemiAnnualDiscount,
        calculateDiscount,
        resetCartHandler,
        productsData,
        setProductsData,
        details,
        setDetails,
        loginType,
        setLoginType,
        verificationDone,
        setVerificationDone
    };
    return (
        <AccountContext.Provider value={data}>
            {children}
        </AccountContext.Provider>
    );
};

export const useAccount = () => {
    return useContext(AccountContext);


};
