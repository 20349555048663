import React from "react";
import { AccountProvider } from "./src/utils/AccountContext";
import "./src/styles/global.css";
import Layout from "./src/layout";
import { navigate } from "gatsby";
import redirects from "./redirects.json";
export const wrapRootElement = ({ element }) => {
  return (
    <AccountProvider>
      <Layout>{element}</Layout>
    </AccountProvider>
  );
};

export const onInitialClientRender = () => {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'G-WKEWTZ0B2H', { anonymize_ip: true });

    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-WKEWTZ0B2H`;
    document.head.appendChild(script);
  }
};

export const onPreRouteUpdate = ({ location }) => {
  if (!redirects || redirects.length === 0) return;
  const redirect = redirects.find((r) => r.fromPath === location.pathname);
  if (redirect && location.pathname !== redirect.toPath) {
    navigate(redirect.toPath, { replace: true });
  }
};


